<template>
  <div
    class="col-12 col-md-4 col-lg-4 col-xl-3 mb-2"
    :class="[buildingShow ? '' : 'd-none']"
  >
    <b-card class="card-building-item">
      <div class="card-overlay"></div>
      <div class="card-content" @click="navigateToBuildingDetail">
        <div class="d-flex justify-content-between card-building-actions">
          <div class="handle"><i class="fa fa-bars"></i></div>
          <!-- <div class="d-flex">
                        <a href="#" class="text-danger ml-2" @click="confirmDelete" v-b-tooltip.hover title="Delete Building"><i class="fa fa-trash-alt"></i></a>
                    </div> -->
        </div>

        <b-card-title class="d-flex align-items-start">
          <i class="fa fa-building mr-2"></i>
          <span>{{ buildingName }}</span>
          <a
            href="#"
            class="update-building-btn mt-2"
            v-b-modal="'form-update-building-' + buildingID"
            v-b-tooltip.hover
            title="Update Building"
          >
            <i class="fa fa-edit"></i>
          </a>
        </b-card-title>

        <b-card-text>
          <span class="mr-2">{{ buildingFloors.length }} Floors</span>
          <span>{{ buildingDevices.length }} Devices</span>
        </b-card-text>
      </div>
    </b-card>

    <b-modal
      :id="'form-update-building-' + buildingID"
      :title="'Update Building - ' + buildingName"
      ref="formUpdateBuilding"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitUpdateForm">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdate.name"
            type="text"
            placeholder="Enter building name"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingItem",
  props: {
    buildingID: {
      type: Number,
      required: true
    },
    buildingName: {
      type: String,
      required: true
    },
    buildingFloors: {
      type: Array,
      required: true
    },
    buildingDevices: {
      type: Array,
      required: true
    },
    buildingCreated: {
      type: String,
      required: false
    },
    buildingCreatedBy: {
      type: Number,
      required: false
    },
    buildingIndex: {
      type: Number,
      required: false
    },
    buildingShow: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      formUpdate: {
        name: this.buildingName
      }
    };
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    }),
    createdDisplay: function () {
      var created_date = moment(this.buildingCreated).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      return created_date;
    }
  },
  mounted: function () {
    // pass
  },
  methods: {
    onSubmitUpdateForm: function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdate)
        .then((data) => {
          // console.log(data);
          var update_data = {
            id: $this.buildingID,
            data: {
              name: $this.formUpdate.name
            }
          };
          $this.$store.dispatch("psim/updateBuilding", update_data);
          $this.$refs.formUpdateBuilding.hide();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated " + $this.formUpdate.name + " successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    confirmDelete: function (e) {
      e.preventDefault();
      var $this = this;
      this.$bvModal
        .msgBoxConfirm(
          "All floors for " +
            $this.buildingName +
            " will be deleted as well. Confirm deleting " +
            $this.buildingName +
            "?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            var API_URL =
              $this.getAPIServerURL +
              "/floorplans/buildings/" +
              $this.buildingID +
              "/";
            const client = $this.$root.getAjaxFetchClient();
            client
              .deleteRequest(API_URL)
              .then((data) => {
                this.$store.dispatch("psim/deleteBuilding", $this.buildingID);
                this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Deleted building successfully",
                  message_type: "success"
                });

                // TOCLEAN
                // } else {
                //     this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
                // }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                // if (err.status == 401) {
                //   $this.$store.dispatch("session/logoutSession");
                // }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    navigateToBuildingDetail: function (e) {
      // console.log("Clicked Card", e.target.className);
      if (e.target.className == "fa fa-edit") {
        //
      } else if (e.target.className == "fa fa-trash-alt") {
        //
      } else if (e.target.className == "handle") {
        //
      } else {
        this.$router
          .push({
            name: "Building Detail",
            params: { building_id: this.buildingID }
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="scss">
@import "./BuildingItem.scss";
</style>
