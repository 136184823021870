<template>
  <div class="container-fluid pt-4">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex align-items-end">
          <h1 class="mb-0">{{ pageTitle }}</h1>
          <span class="ml-2">Total buildings: {{ buildingCount }}</span>
        </div>
      </div>
      <div
        class="col-12 col-md-6 mt-2 mt-md-0 d-flex justify-content-start justify-content-md-end"
      >
        <div class="w-50 d-inline-block mr-2">
          <b-form-input
            id="search_bar"
            size="sm"
            type="text"
            placeholder="Search..."
            v-model="searchTerms"
          ></b-form-input>
        </div>

        <b-button variant="success" size="sm" v-b-modal.form-add-building
          ><i class="fa fa-plus mr-1"></i>Add Building</b-button
        >
      </div>
    </div>

    <div v-if="buildingListEmpty" class="row">
      <div class="col-12 py-4">No buildings found</div>
    </div>

    <draggable
      class="row pt-3"
      ghost-class="ghost"
      v-model="buildingList"
      handle=".handle"
    >
      <BuildingItem
        v-for="building in buildingList"
        :key="building.id"
        :buildingID="building.id"
        :buildingName="building.name"
        :buildingFloors="building.floors"
        :buildingDevices="building.devices"
        :buildingCreated="building.created"
        :buildingCreatedBy="building.created_by"
        :buildingIndex="building.index"
        :buildingShow="building.isShow"
      ></BuildingItem>
    </draggable>

    <b-modal
      id="form-add-building"
      title="Add Building"
      ref="formAddBuilding"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitAddForm">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formAdd.name"
            type="text"
            placeholder="Enter building name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-floors"
          label="Floors:"
          label-for="input-floors"
          description="Minimum number is 1"
        >
          <b-form-input
            id="input-floors"
            v-model="formAdd.floors"
            type="number"
            min="1"
            max="200"
            placeholder="Number of floors"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import draggable from "vuedraggable";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import BuildingItem from "@/components/SettingsFloorplan/BuildingItem/BuildingItem.vue";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingList",
  components: {
    BuildingItem,
    draggable
  },
  data: function () {
    return {
      pageTitle: "",
      formAdd: {
        name: null,
        floors: 1
      },
      buildingCount: 0,
      searchTerms: "",
      buildingListEmpty: false
    };
  },
  computed: {
    ...mapGetters({
      isBuildingsInitiated: "psim/isBuildingsInitiated",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    buildingList: {
      get() {
        var buildings = this.$store.getters["psim/getBuildings"]();
        // console.log(buildings);
        // console.log("getting building list")
        for (var i in buildings) {
          var building = buildings[i];
          var cameras = this.$store.getters["psim/getCameras"]({
            building__id: building.id
          });
          var doors = this.$store.getters["psim/getDoors"]({
            building__id: building.id
          });
          var devices = cameras.concat(doors);
          building["devices"] = devices;

          if (
            building.name.toUpperCase().includes(this.searchTerms.toUpperCase())
          ) {
            building["isShow"] = true;
          } else {
            building["isShow"] = false;
          }
        }
        // console.log("buildingList", buildings);
        return buildings;
      },
      set(value) {
        var building_arrangement = [];
        for (var i in value) {
          building_arrangement.push({ id: value[i].id, index: parseInt(i) });
          value[i].index = parseInt(i);
          this.$store.dispatch("psim/updateBuilding", {
            id: value[i].id,
            data: { index: parseInt(i) }
          });
          this.$store.dispatch("psim/updateBuildingIndex", {
            id: value[i].id,
            index: parseInt(i)
          });
        }
        this.rearrangeBuildings(building_arrangement);
      }
    }
  },
  watch: {
    isBuildingsInitiated: {
      handler: function (n, o) {
        // console.log(n);
        if (n == true) {
          this.initView();
        }
      },
      deep: true
    },
    buildingList: {
      handler: function (n, o) {
        var is_empty = true;
        if (this.isBuildingsInitiated) {
          for (var i in n) {
            var building = n[i];
            if (building.isShow) {
              is_empty = false;
            }
          }
          this.buildingListEmpty = is_empty;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    if (this.isBuildingsInitiated) {
      this.initView();
    }
  },
  methods: {
    initView: function () {
      // console.log("Viewing SettingsFloorplan - BuildingList");
      var $this = this;
      $this.pageTitle = "Manage Buildings";
      $this.buildingCount = $this.buildingList.length;
    },
    onSubmitAddForm: function (e) {
      e.preventDefault();
      var $this = this;
      // console.log(this.form);
      var API_URL = $this.getAPIServerURL + "/floorplans/buildings/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, $this.formAdd)
        .then((data) => {
          var building_data = data.id != undefined ? data : null;

          if (building_data != null) {
            $this.$store.dispatch("psim/addBuilding", building_data);
          }

          $this.$refs.formAddBuilding.hide();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Added " + building_data.name + " successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    rearrangeBuildings: function (arrangement) {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/floorplans/buildings-index/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, { building_ids: arrangement })
        .then((data) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated buildings sequence successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style lang="scss">
@import "./SettingsFloorplan.scss";
</style>
